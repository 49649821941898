import './shared';
import '../styles/home_index.css';

import 'owl.carousel/dist/owl.carousel.min';
import 'owl.carousel/dist/assets/owl.carousel.min.css';

const _dir = $('html').attr('dir');
const _isRtl = (_dir === 'rtl');


((
    ($) => {

    }
)
(jQuery));

docReady(() => {
    // console.log('timerStart:: '+timerStart);
    // console.log('Datenow:: '+Date.now());
    // console.log(Date.now() - timerStart);

    const _delay = Date.now() - timerStart;

    function load_js() {
        const _dashboard_assets = window.application.dashboard_assets;
        const script1 = _dashboard_assets + 'assets/modules/map/node_modules/leaflet/dist/leaflet.js';
        const script2 = _dashboard_assets + 'assets/modules/map/perfect-scrollbar-master/perfect.js';
        const script3 = _dashboard_assets + 'assets/modules/map/Leaflet.awesome-markers/dist/leaflet.awesome-markers.min.js';
        $('.map-block').append(' <script class="map-script" type="text/javascript" defer src="' + script1 + '"></script>');
        $('.map-block').append(' <script class="map-script" type="text/javascript" defer src="' + script2 + '"></script>');
        $('.map-block').append(' <script class="map-script" type="text/javascript" defer src="' + script3 + '"></script>');
    }

    function LoadMap() {
        let _url = $('.js-map').attr('data-map-url');
        let _mapBlock = $('.map-block');
        $.ajax({
            method: 'GET',
            url: _url,
            beforeSend: function () {
                _mapBlock.find('.js-map').addClass('loading');
                setTimeout(load_js, 100);
            },
            success: function (response) {
                setTimeout(function () {
                        _mapBlock.find('.js-map').removeClass('loading');
                        _mapBlock.find('.js-map').html(response);
                    },
                    _delay);
            },
        });
    }

    let map = document.querySelector('.map-block');
    let isLoad = false;
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting && !isLoad) {
                LoadMap();
                isLoad = true;
                observer.unobserve(entry.target)
            }
        })
    });
    observer.observe(map)

    const blocks = document.querySelectorAll('.block');
    let blockLoad = false;
    const observerBlock = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            //console.log(entry)
            entry.target.classList.toggle("show", entry.isIntersecting);
            blockLoad = true;
            if (blockLoad) {
                LoadContent();
            }
            if (entry.isIntersecting) observerBlock.unobserve(entry.target);
        })
    });

    blocks.forEach(block => {
        observerBlock.observe(block)
    })

    if ($(window).width() < 768) {
        $('.slider-item').each(function (i, item) {
            const _item = $(item);
            const _desc = _item.find('.slider-desc span');
            if (_desc['length']) {
                if (!(_desc.html().trim() === '') && (_desc.html().length > 100)) {
                    _desc.html(_desc.html().substring(0, 100) + '...');
                }
            }
        });
    }

    if ($(window).width() < 768) {
        $('.home-list5-item').each(function (i, item) {
            const _item = $(item);
            const _desc = _item.find('.list5-desc span');
            if (_desc['length']) {
                if (!(_desc.html().trim() === '') && (_desc.html().length > 200)) {
                    _desc.html(_desc.html().substring(0, 200) + '...');
                }
            }
        });
    }

    $('.js-main-slider').owlCarousel({
        loop: false,
        margin: 0,
        responsiveClass: true,
        rtl: _isRtl,
        autoplay: false,
        items: 1,
        dots: true,
        nav: false,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        onInitialized: function (event) {
            const element = event.target;
            $(element).closest('.loading').removeClass('loading');
            $('.js-main-slider-init').remove();
        },
    });

    function LoadContent() {
        $('.list1-carousel').owlCarousel({
            nav: false,
            rtl: _isRtl,
            dots: true,
            lazyLoad: true,
            autoplay: false,
            loop: false,
            slideTransition: 'ease',
            autoplayTimeout: 5000,
            autoplaySpeed: 1000,
            autoplayHoverPause: true,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                    margin: 0,
                },
                576: {
                    items: 2,
                    margin: 20,
                },
                1000: {
                    items: 3,
                    margin: 20,
                }
            },
            onInitialized: function (event) {
                const element = event.target;
                $(element).closest('.loading').removeClass('loading');
            },
        });

        $('.list4-carousel').owlCarousel({
            nav: false,
            rtl: _isRtl,
            dots: true,
            margin: 20,
            lazyLoad: true,
            autoplay: false,
            loop: false,
            slideTransition: 'ease',
            autoplayTimeout: 5000,
            autoplaySpeed: 1000,
            autoplayHoverPause: true,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                },
                280: {
                    items: 2,
                },
                530: {
                    items: 3,
                },
                767: {
                    items: 4,
                },
                900: {
                    items: 5,
                },
                1010: {
                    items: 6,
                },
                1200: {
                    items: 7,
                }
            },
            onInitialized: function (event) {
                const element = event.target;
                $(element).closest('.loading').removeClass('loading');
            },
        });

        $('.owl-carousel').each(function () {
            $(this).find('.owl-dot').each(function (index) {
                $(this).attr('aria-label', 'owl-dot' + (index + 1));
            });
        });
        $('.leaflet-tile-container').each(function () {
            $(this).find('.leaflet-tile').each(function (index) {
                $(this).attr('alt', 'leaflet-tile' + (index + 1));
            });
        });

    }

    $('.slide-toggle-button').click(function () {
        $(this).next('.slide-toggle-content').slideToggle();
        $(this).toggleClass('active');
    });

    if ($(window).width() < 768) {
        $('.map-block').appendTo('.map-mobile');
    }
});