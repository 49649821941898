import './plugins/docReady';
import 'lazysizes';
import './plugins/mobile/jquery-simple-mobilemenu';

document.addEventListener('lazybeforeunveil', function (e) {
    var bg = e.target.getAttribute('data-bg');
    if (bg) {
        e.target.style.backgroundImage = 'url(' + bg + ')';
    }
});

((
    ($) => {
        if ($(window).width() > 576) {
            let _header = $('.header');
            var _headerHeight = _header.outerHeight();
            console.log(_headerHeight);
            if (_header.hasClass('home-header')) {
                var _headerHeight = _header.outerHeight();
            }
            if ($(window).scrollTop() >= _headerHeight) {
                $('body').css('padding-top', _headerHeight);
                _header.addClass('sticky');
            } else {
                $('body').css('padding-top', '');
                _header.removeClass('sticky');
            }

            $(window).scroll(function () {
                var scroll = $(window).scrollTop();
                if (scroll >= _headerHeight) {
                    $('body').css('padding-top', _headerHeight);
                    _header.addClass('sticky');
                } else {
                    $('body').css('padding-top', '');
                    _header.removeClass('sticky');
                }
            });
        }

    }
)
(jQuery));

docReady(() => {

    if ($(window).width() > 1100) {
        $('.main-menu').css('opacity', '1');
    } else {
        $('.main-menu').css('opacity', '0');
        $(".menu-inner").addClass("mobile-menu");
        $(".mobile-menu").simpleMobileMenu({
            onMenuLoad: function (menu) {
                console.log(menu)
                console.log("menu loaded")
            },
            onMenuToggle: function (menu, opened) {
                console.log(opened)
            },
            "menuStyle": "slide"
        });

        $(".js-burger").click(function () {
            if ($('.js-burger').hasClass("active")) {
                $(this).removeClass('active');
                //$(".sm_menu_outer").removeClass("active");
                setTimeout(function () {
                    $(".sm_menu_outer").removeClass('active');
                }, 200);
                $("body").removeClass("mmactive");
            } else {
                $(this).toggleClass('active');
                //$(".sm_menu_outer").addClass("active");
                setTimeout(function () {
                    $(".sm_menu_outer").addClass('active');
                }, 200);
                $("body").toggleClass("mmactive");
            }
        });

        $('.tabbar ul li .mini-item').on('click touch', function (e) {
            e.preventDefault();
            let _self = $(this);
            if (!_self.parent().hasClass('active')) {
                _self.parent().parent().children('ul').removeClass('active');
                _self.parent().addClass('active');
            } else {
                _self.parent().removeClass('active');
            }

            if (_self.is('a')) {
                const _href = $(this).attr('href');
                setTimeout(function () {
                    window.location = _href;
                }, 500);
            }
        });

        $('.js-mobile-social .mobile--social li a').click(function () {
            const _item = $(this);
            const _target = _item.prop('target');
            const _href = _item.attr('href');
            window.open(_href, _target);
        });

        $('.close').click(function () {
            $('.mini-mobile-item').removeClass("active");
        });
    }

    let _lottie_loaded = false;
    let _page_loaded = false;
    if (!_page_loaded) {
        $('html').on('mousemove click touchstart touchmove', function (e) {
            if (!_lottie_loaded) {
                const _url = application.lottie_url;
                var lottieScript = document.createElement('script');
                lottieScript.setAttribute('src', _url);
                lottieScript.setAttribute('async', 'async');
                document.head.appendChild(lottieScript);

                _lottie_loaded = true;
            }
            _page_loaded = true;
        });
    }

});